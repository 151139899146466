var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{on:{"submit":_vm.userSignUp}},[_c('FormulateInput',{attrs:{"type":"text","name":"username","placeholder":_vm.labels.username,"error-behavior":"blur","validation":"required|min:2,length","validation-messages":{ required: _vm.labels.required }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('FormulateInput',{attrs:{"type":"text","name":"first name","placeholder":_vm.labels.first_name,"error-behavior":"blur","validation":"^required|min:2,length|matches:/^[A-Za-z]+$/","validation-messages":{
          required: _vm.labels.required,
          matches: _vm.labels.firstNameHint,
        }}})],1),_c('div',{staticClass:"col-lg-6"},[_c('FormulateInput',{attrs:{"type":"text","name":"last name","placeholder":_vm.labels.last_name,"error-behavior":"blur","validation":"^required|min:2,length|matches:/^[A-Za-z]+$/","validation-messages":{
          required: _vm.labels.required,
          matches: _vm.labels.lastNameHint,
        }}})],1)]),_c('FormulateInput',{attrs:{"type":"email","name":"email","placeholder":_vm.labels.email,"error-behavior":"blur","validation":"required","validation-messages":{
      required: _vm.labels.required,
      email: _vm.labels.emailHint,
    }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('FormulateInput',{attrs:{"type":"password","name":"password","placeholder":_vm.labels.password,"error-behavior":"blur","validation":("required|min:8,max:32,length|matches:" + _vm.passCheck),"validation-messages":{
          required: _vm.labels.required,
          matches: _vm.labels.passHint,
        }}})],1),_c('div',{staticClass:"col-lg-6"},[_c('FormulateInput',{attrs:{"type":"password","name":"confirm","placeholder":_vm.labels.confirm_password,"error-behavior":"blur","validation":"required|confirm:password","validation-messages":{
          required: _vm.labels.required,
          confirm: _vm.labels.passCorfirmHint,
        }}})],1)]),_c('Checkbox',{attrs:{"label":_vm.labels.i_agree_with_the_terms_of_use('/agb/'),"borderRadius":"0","bgColor":"transparent","padding":"0","color":("" + (_vm.colorsHtml.black)),"name":"privacy-policy"}}),_c('Checkbox',{attrs:{"label":_vm.labels.i_agree_to_the_privacy_policy('/datenschutz/'),"borderRadius":"0","bgColor":"transparent","padding":"0","color":("" + (_vm.colorsHtml.black)),"name":"privacy-policy"}}),_c('FormulateInput',{attrs:{"type":"submit","label":_vm.labels.send}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }