<template>
  <FormulateForm @submit="userSignUp">
    <FormulateInput
      type="text"
      name="username"
      :placeholder="labels.username"
      error-behavior="blur"
      validation="required|min:2,length"
      :validation-messages="{ required: labels.required }"
    />
    <div class="row">
      <div class="col-lg-6">
        <FormulateInput
          type="text"
          name="first name"
          :placeholder="labels.first_name"
          error-behavior="blur"
          :validation="`^required|min:2,length|matches:/^[A-Za-z]+$/`"
          :validation-messages="{
            required: labels.required,
            matches: labels.firstNameHint,
          }"
        />
      </div>
      <div class="col-lg-6">
        <FormulateInput
          type="text"
          name="last name"
          :placeholder="labels.last_name"
          error-behavior="blur"
          :validation="`^required|min:2,length|matches:/^[A-Za-z]+$/`"
          :validation-messages="{
            required: labels.required,
            matches: labels.lastNameHint,
          }"
        />
      </div>
    </div>

    <FormulateInput
      type="email"
      name="email"
      :placeholder="labels.email"
      error-behavior="blur"
      validation="required"
      :validation-messages="{
        required: labels.required,
        email: labels.emailHint,
      }"
    />

    <div class="row">
      <div class="col-lg-6">
        <FormulateInput
          type="password"
          name="password"
          :placeholder="labels.password"
          error-behavior="blur"
          :validation="`required|min:8,max:32,length|matches:${passCheck}`"
          :validation-messages="{
            required: labels.required,
            matches: labels.passHint,
          }"
        />
      </div>
      <div class="col-lg-6">
        <FormulateInput
          type="password"
          name="confirm"
          :placeholder="labels.confirm_password"
          error-behavior="blur"
          validation="required|confirm:password"
          :validation-messages="{
            required: labels.required,
            confirm: labels.passCorfirmHint,
          }"
        />
      </div>
    </div>

    <Checkbox
      :label="labels.i_agree_with_the_terms_of_use('/agb/')"
      borderRadius="0"
      bgColor="transparent"
      padding="0"
      :color="`${colorsHtml.black}`"
      name="privacy-policy"
    />

    <Checkbox
      :label="labels.i_agree_to_the_privacy_policy('/datenschutz/')"
      borderRadius="0"
      bgColor="transparent"
      padding="0"
      :color="`${colorsHtml.black}`"
      name="privacy-policy"
    />

    <FormulateInput type="submit" :label="labels.send" />
  </FormulateForm>
</template>

<script>
import Checkbox from "@/components/Input/Checkbox.vue";

import { mapActions, mapGetters } from "vuex";
import colorsHtml from "/colors.config.json";
export default {
  components: { Checkbox },
  data() {
    return {
      colorsHtml: colorsHtml,
      passCheck: new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!#%*?&])[A-Za-z[0-9]@$!#%*?&]{8,}$"
      ),
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("user", {
      signUp: "signUp",
    }),
    userSignUp(data) {
      this.signUp(data)
        .then((res) => {
          if (res.success) {
            this.$router.push("/");
            this.addNotification({
              variant: "success",
              msg: res.msg,
              labels: this.labels,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .formulate-input[data-classification="button"][data-type="submit"] button {
    width: 100%;
  }
}
</style>
